@import "../../../assets/scss/theme/theme1/theme.scss";

.filters-btn {
    .chart-button {
        width: 100px;
        height: 40px;
        color: #222;
        font-size: 15px;
        border: none;
        &:hover {
            background-color: $theme-primary-color;
            color: #fff;
        }
    }
    .active {
        color: #fff;
    }
    .date-picker {
        width: 170px;
        animation: none;
        color: $theme-primary-color;
    }
    .month-btn:not(.active), .year-btn:not(.active){
        border: 1px solid #2795ae;
    }
}

@media (max-width: 500px) {
    .graph-card{
        .filters-btn{
            .filter-btn-con{
                flex-direction: column;
                justify-content: inherit;
                width: 100%;
                .month-btn, .year-btn, .date-picker{
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}