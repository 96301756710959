.app-custom-text {
    .center {
        min-width: 50%;
    }
    &.border-right,
    &.border-left {
        position: relative;
        &::after {
            content: "";
            width: 1px;
            height: 44px;
            background: rgba(149, 149, 149, 0.8);
            opacity: 0.1;
            position: absolute;
        }
    }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
    .app-custom-text.border-right::after,
    .app-custom-text.border-left::after {
        display: none; /* Hide borders on mobile devices */
    }
}

/* Media query for tablets and small laptops */
@media (min-width: 768px) and (max-width: 1023px) {
    .app-custom-text.border-right::after,
    .app-custom-text.border-left::after {
        display: none; /* Hide borders on tablet devices */
    }
}

/* Media query for regular laptops and desktops */
@media (min-width: 1024px) and (max-width: 1279px) {
    /* Styles for regular laptops and desktops */
}

/* Media query for larger desktop screens */
@media (min-width: 1280px) {
    /* Styles for larger desktop screens */
}
