
  .btns-main-con {
    width: 75%;
  }

@media (max-width: 500px) {
  .btns-main-con {
    width: 100%;
  }
}
