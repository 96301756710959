
.body-search {
  
    .form-control {
      // Old Searchbar CSS
      // border: none;
      // height: 38px;
      // padding-left: 40px;
      // padding-right: 20px;
      // box-shadow: none;
      // border-radius: 4px;

      width: 300px;
      height: 40px; 
      border: 1px solid #ccc; 
      border-radius: 4px; 
      padding-left: 40px;
      padding-right: 20px;
      outline: none; 
      font-weight: 500;
    }
    span {
      position: absolute;
      z-index: 3;
      font-size: 20px;
      line-height: 38px;
      left: 13px;
      top: 0;
    }
  }