.ad-management-details-option-container {
    display: flex;
    justify-content: flex-end;
}

.ad-input {
    // color: #a5a5a5 !important;
    font-family: "Poppins";
    font-size: 12px !important;
    font-weight: 400 !important;
}
.ad-mang-edit-btn-margin {
    margin-left: 15px;
}
.card-shadow {
    box-shadow: 0px 3px 15px rgba(151, 145, 145, 0.28) !important;
}

@media (max-width: 767px) {
    .custom-card * {
        // flex-direction: column !important;
        .text-margin {
            margin-top: 7px;
            > h5 {
                margin-left: 10px;
            }
        }
        
        .custom-btn-lg {
            width: 100% !important;
        }
        .custom-btn-lg-secondary {
            margin-top: 5px;
        }
        .custom-btn.custom-btn-lg-secondary {
            min-width: 100%;
        }
    }
    .ad-modal-resopnsiveness {
        .custom-btn-lg {
            width: 100% !important;
        }
        .custom-btn-lg-secondary {
            margin-top: 5px;
        }
        .custom-btn.custom-btn-lg-secondary {
            min-width: 100%;
        }
    }
    .ad-btn-responsiveness {
        padding-top: 1.5rem;
        .ad-mang-edit-btn-margin{
            .ad-btn{
                    width: 100%;
                    margin-right: 0px;
                    margin-left: 0px;
            }
        }
    }
}
