.custom-text-input {
    position: relative;
    &:not(.hide-shadow) {
        input,
        select {
            box-shadow: 0px 1px 4px #8b8a8a29;
        }
    }
    input,
    select {
        border-radius: 4px;
        border: 1px solid #ebebeb;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
        height: 36px;
        &::placeholder {
            color: #a5a5a5;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
        }
        
    }
    .input-icon-padding{
        padding-right: 2rem;
    }
    select {
        padding-right: 30px !important;
    }
    input {
        &[type="date"] {
            padding-right: 12px !important;
        }
    }
    &:not(.hide-default-icon) {
        input {
            padding-left: 47px;
            padding-right: 47px;
            &::placeholder {
                color: #2e2e30;
            }
            &[type="email"] {
                background: url("../../assets/images/envelop-icon.png") no-repeat 20px center #fff;
            }
            &[type="password"] {
                background: url("../../assets/images/lock-icon.png") no-repeat 20px center #fff;
            }
        }
    }
    textarea.form-control {
        min-height: 100px;
        resize: none;
        border: 1px solid #ebebeb;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);

        &::placeholder {
            color: #a5a5a5;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
        }
    }

    .input-icon {
        position: absolute;
        top: 50%; /* Position from the middle vertically */
        transform: translateY(-50%); /* Adjust for centering */
        right: 12px;
    }
}

.app-switch-input {
    &.sm {
        // Write as per need
    }

    &.md {
        // Write as per need
    }
    &.lg {
        width: 63px !important;
        height: 30px !important;
        padding: 4px !important;
    }
}
