@import "./bootstrap.scss";
@import "../../theme.scss";
@import "./variables";
@import "../../app.scss";
@import "functions";

body {
    * {
        outline: none;
    }
    background-color: $white;
}

.fw-600 {
    font-weight: $font-weight-semibold;
}

.pointer {
    cursor: pointer;
}

.font-clr-theme-secondary {
    color: $theme-font-secondary-color;
}

.clr-theme-primary {
    color: $theme-primary-color !important;
}

.clr-theme-primary-light {
    color: $theme-primary-light-color !important;
}

.clr-theme-secondary {
    color: $theme-secondary-color;
}

.clr-theme-secondary-light {
    color: $theme-secondary-light-color;
}

.clr-theme-blue {
    color: $blue;
}

.bg-grad-primary {
    background: transparent
        linear-gradient(53deg, $theme-secondary-color 0%, $theme-primary-color 100%) 0% 0%;
}

.bg-theme-secondary {
    background-color: $theme-secondary-color !important;
}

.bg-theme-secondary-light {
    background-color: $theme-secondary-light-color !important;
}

.bg-theme-primary-light {
    background-color: $theme-primary-light-color !important;
}

.bg-clr-blue {
    background-color: #3785ae;
}

.clr-blue {
    color: #3785ae !important;
}

.clr-theme-red {
    color: $red;
}

.font-black {
    color: black !important;
}

.bg-red {
    background-color: #d84243 !important;
}

.bg-yellow {
    background-color: #e5bc17 !important;
}

.clr-yellow {
    color: #dcb30e;
}

.clr-purple {
    color: #7c2c83;
}

.bg-grad-theme1 {
    background: transparent
        linear-gradient(58deg, $cs-theme-1-primary 0%, $cs-theme-1-secondary 100%) 0% 0%;
}

.bg-grad-theme2 {
    background: transparent
        linear-gradient(58deg, $cs-theme-2-primary 0%, $cs-theme-2-secondary 100%) 0% 0%;
}

.bg-grad-theme3 {
    background: transparent
        linear-gradient(58deg, $cs-theme-3-primary 0%, $cs-theme-3-secondary 100%) 0% 0%;
}

.bg-grad-theme4 {
    background: transparent
        linear-gradient(58deg, $cs-theme-4-primary 0%, $cs-theme-4-secondary 100%) 0% 0%;
}

.bg-grad-theme5 {
    background: transparent
        linear-gradient(58deg, $cs-theme-5-primary 0%, $cs-theme-5-secondary 100%) 0% 0%;
}

.bg-grad-theme6 {
    background: transparent
        linear-gradient(58deg, $cs-theme-6-primary 0%, $cs-theme-6-secondary 100%) 0% 0%;
}

.cs-options-top-right {
    top: 0;
    right: 0;
    z-index: 1;
}

// BaseCode: To add background image on sidebar
// .vertical-menu {
//   div.navbar-brand-box {
//     background: none !important;
//   }
//   background: url("../../../images/theme-one-sidebar-bg.png") !important;
// }

.bv-profile {
    .custom-text-input {
        input,
        select {
            background-color: $theme-secondary-color !important;
        }
    }
}

.cs-select-dropdown {
    &-green {
        select.form-select {
            background-color: $light-green !important;
            color: $dark-green !important;
        }
    }
}

.bv-right-hr {
    color: #2e2e30;
    &::after {
        content: "";
        display: inline-block;
        height: 1px;
        background-color: #b8b8b8;
        width: 35%;
        margin-left: 14px;
    }
}

.cs-equal-card-wrap {
    .cs-equal-card-left {
        max-height: 380px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
    .cs-equal-card-right-1 {
        max-height: 180px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
    .cs-equal-card-right-2 {
        max-height: 180px;
        overflow-y: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #3785ae;
        }
    }
}

.bv-auth-code {
    input {
        caret-color: $theme-secondary-color;
        &:not(:focus) {
            border-color: $theme-primary-color;
        }
        &:focus {
            border-color: $theme-secondary-color;
        }
    }
}

@media only screen and (max-width: 1634px) {
    .cs-user-details-approve-user-btns {
        .custom-btn.custom-btn-lg {
            min-width: auto;
        }
    }
}

// BaseCode

.custom-card {
    border-radius: 3px !important;
    // box-shadow: 0px 10px 28px 0px rgba(18, 38, 63, 0.07) !important;

    filter: drop-shadow(0px 3px 15px rgba(151, 145, 145, 0.15));
}

/* Global Scrollbar Styling */

/* Customize scrollbar width and background color */
::-webkit-scrollbar {
    height: 5px;
    width: 10px;
    background-color: #f0f0f0;
}

/* Customize scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: $theme-primary-color;
    border-radius: 10px;
}

/* Customize scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: $theme-primary-color;
}

/* Customize scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

// For input white icons color, if use box icon
.icon-grey {
    color: #bcbcbe;
}
// Specific for the project
.app-text-gray {
    color: #40393a;
}

.text-left {
    text-align: left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
}
