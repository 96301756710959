.pac-container {
    z-index: 99999999;
    &:after {
        background-image: none !important;
        height: 0px;
    }
}

.GoogleAutoComplete {
    position: relative;
    &:not(.hide-shadow) {
        input,
        select {
            box-shadow: 0px 1px 4px #8b8a8a29;
        }
    }
    input,
    select {
        border-radius: 4px;
        border: 1px solid #ebebeb;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
        height: 36px;
        &::placeholder {
            color: #a5a5a5;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
        }
    }
    select {
        padding-right: 30px !important;
    }
    input {
        &[type="date"] {
            padding-right: 12px !important;
        }
    }
}

.app-switch-input {
    &.sm {
        // Write as per need
    }

    &.md {
        // Write as per need
    }
    &.lg {
        width: 63px !important;
        height: 30px !important;
        padding: 4px !important;
    }
}
