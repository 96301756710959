.app-auth-wrapper {
    background-color: #fff;
    .auth-wrap-left-content {
        position: relative;
    }
    .auth-wrap-right-content {
        // css here frt right side
    }
    a {
        &:focus {
            text-decoration: underline !important;
        }
    }
    .main-image {
        position: absolute;
        width: 161px;
        height: 234px;
        top: 0px;
        left: 40px;
    }
}

.bv-forget-wrap {
    .responsiveness {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 792px) and (min-width: 768px) {
    .bv-forget-wrap {
        .responsiveness {
            flex-direction: column;
        }
    }
}

.auth-wrap-right-content {
    // css here frt right side
    height: 100%;
    display: grid;
    // align-items: center;
}

@media only screen and (max-width: 578px) {
    .auth-wrap-right-content {
        margin: 0 3rem;
    }
}
