.analytics-loader-con {
  height: 90px !important;
  margin-bottom: 24px;
}

.graph-loader{
  height: 335px !important;
}

.roundsection-loader{
  height: 233px;
  background-color: white;
  box-shadow: 0px 4px 18px 0px rgba(18, 38, 63, 0.07);
  padding: 1.25rem;
}

.analytics-image-container{
  background-color: #F3F3F3;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-right: 10px;
}
