.analytics-list-box {
    height: 100%;
    border-radius: 10px !important;

    & .left-content {
        color: black;
        width: 50%;
        border-bottom-left-radius: 10px !important;
    }
    & .right-content {
        max-width: 0; /* Required for the ellipsis to work */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-right-radius: 10px !important;
    }
    & .analytics-list-body {
    }
}

.table-section {
    & .header {
        background-color: #f3f3f3;
        color: black;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    & .table-body * {
        background-color: white;
    }
}
