.profile-header-container {
    height: 150px;
    .suspend-btn-conatiner {
        height: 100%;
        padding: 20px;
    }
}

.round-status-common {
    width: 90px;
    height: 26px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.round-scheduled {
    border: 1px solid #2795ae;
    color: #2795ae !important;
}
.round-playing {
    border: 1px solid #40ec9c;
    color: #40ec9c !important;
}
.round-completed {
    border: 1px solid #2aae27;
    color: #2aae27 !important;
}
.round-canceled {
    border: 1px solid #ff2b2b;
    color: #ff2b2b !important;
}

.pofile-data-not-found{
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        font-weight: bold;
    }
}