@import "../../assets/scss/theme/theme1/functions";
@import "../../assets/scss/theme/theme1/theme.scss";

.account-responsiveness {
    .profile-image {
        width: 130px;
        height: 130px;
        border: 5px solid #2795AE;
    }
    .profile-edit-icon {
        left: 113px;
        bottom: 23px;
    }
}
.account-input {
    color: #a5a5a5 !important;
    font-family: "Poppins";
    font-size: 12px !important;
    font-weight: 400 !important;
}


@media (max-width: 767px) {
    /* For mobile phones: */
    .account-responsiveness * {
        display: flex;
        justify-content: center;
        .profile-edit-icon {
            left: 280px;
        }
        .margin-responsive {
            margin-top: 5px;
            > div {
                width: 100%;
            }
        }
        .text-muted {
            flex-direction: column;
        }
        .custom-btn-lg {
            width: 100%;
        }
        .account-btns-responsiveness {
            margin-top: 0 !important;
            flex-direction: column;
            min-width: 100%;
            .custom-btn-lg-secondary {
                margin-top: 5px;
            }
        }
    }
}

@media (max-width: 540px) {
    /* For mobile phones: */
    .account-responsiveness * {
        .profile-image {
            width: 50%;
            height: 50%;
        }
        .profile-edit-icon {
            left: 27%;
        }
    }
}
