.otp-input {
    width: 15%;
    height: 15%;
    margin: 5px;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    font-size: 1.01562rem;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    border-color: #ced4da;
    background-color: white;
}
